import React, { CSSProperties, SyntheticEvent, useRef, useState } from 'react';
import { Image } from '@theme-ui/components';
import { useTranslation } from 'react-i18next';
import { AuthVariant } from './AuthBox';
import Button from '../../01_atoms/Button/Button';
import PasswordInput from '../../01_atoms/PasswordInput/PasswordInput';
import Text from '../../01_atoms/Text/Text';
import TextInput from '../../01_atoms/TextInput/TextInput';
import { Box } from 'theme-ui';
import CheckboxCustom from '../../01_atoms/Checkbox/SignIn';

import IconFb from '../../../assets/icons/iconFb.svg';
import IconGoogle from '../../../assets/icons/iconGoogle.svg';
import IconApple from '../../../assets/icons/iconApple.svg';

type SignInFormProps = {
  errorInformation: null | {
    position: string;
    element: React.ReactNode;
  };
  onClickFacebookLogin?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  onClickGoogleLogin?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  onClickAppleLogin?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  setEmail: (v: string) => void;
  setPassword: (v: string) => void;
  setCurrentVariant: (variant: AuthVariant) => void;
  clientCheck: (client: string) => Promise<boolean>;
  loading: boolean;
};

const Style = {
  socialMediaButton: {
    cursor: 'pointer',
    marginTop: '0.5rem',
    backgroundColor: 'rgb(243, 243, 243)',
    flexDirection: 'row' as CSSProperties['flexDirection'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    maxHeight: 47,
  },
  buttonIcon: {
    width: '8%',
  },
  buttonText: {
    fontWeight: 'bold',
    width: '92%',
  },
  loginButton: {
    cursor: 'pointer',
    marginTop: '3rem',
    display: 'block',
    alignSelf: 'flex-end',
  },
  voucherContainer: {
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
    display: 'flex',
    flexDirection: 'column' as CSSProperties['flexDirection'],
    gap: '0.5rem',
  },
  voucherLabel: {
    color: 'rgb(17, 17, 17)',
    fontSize: '16px',
  },
  container: {
    padding: '2rem',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  title: {
    textAlign: 'center' as const,
    fontSize: '2rem',
    marginBottom: '1.5rem',
  },
  input: {
    marginBottom: '1rem',
    padding: '0.75rem',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '0.75rem 1.5rem',
    borderRadius: '4px',
    fontSize: '1rem',
  },
};

export function debounce<T extends any[]>(fn: (...args: T) => void, delay: number) {
  let timeout: NodeJS.Timeout;
  return (...args: T) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(...args), delay);
  };
}

export default function SignInForm({
  errorInformation,
  clientCheck,
  onClickGoogleLogin,
  onClickAppleLogin,
  setEmail,
  setPassword,
  setCurrentVariant,
  loading,
}: SignInFormProps) {
  const { t } = useTranslation();

  const setClientDebounced = useRef(
    debounce(async (value: string) => {
      await clientCheck(value);
    }, 600),
  ).current;

  const [hasVoucher, setHasVoucher] = useState(false);
  const [voucherCode, setVoucherCode] = useState('');

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setHasVoucher(checked);
    console.log('Checkbox checked:', checked);
  };

  return (
    <React.Fragment>
      <Button
        onClick={onClickGoogleLogin}
        style={Style.socialMediaButton}
        type="button"
      >
        <Image style={Style.buttonIcon} src={IconGoogle} />
        <Text sx={Style.buttonText}>{t('LOGIN_GOOGLE')}</Text>
      </Button>

      <Button
        onClick={onClickAppleLogin}
        style={Style.socialMediaButton}
        type="button"
      >
        <Image style={Style.buttonIcon} src={IconApple} />
        <Text sx={Style.buttonText}>{t('LOGIN_APPLE')}</Text>
      </Button>

      {/* Add margin to create space */}
      <div style={{ marginBottom: '20px' }} />

      <TextInput
        id="email"
        type="email"
        name="email"
        placeholder={t('EMAIL_ADDRESS')}
        required
        onChange={(e): void => {
          setEmail(e.target.value);
        }}
        error={errorInformation?.position === 'email' && errorInformation.element}
        disabled={loading}
      />

      <PasswordInput
        id="password"
        name="password"
        onChange={setPassword}
        placeholder={t('PASSWORD')}
        required
        overlayLink={
          <Button
            type="button"
            variant="textGrey"
            sx={{ fontSize: 1 }}
            onClick={(): void => setCurrentVariant('resetPassword')}
          >
            {t('VERGESSEN')}
          </Button>
        }
        error={errorInformation?.position === 'password' && errorInformation.element}
        disabled={loading}
      />

      {/* Voucher Section */}
      <div style={Style.voucherContainer}>
        <Box sx={{ display: 'flex', mb: 1 }}>
          <CheckboxCustom
            id="voucher-checkbox"
            name="voucher"
            checked={hasVoucher}
            onChange={handleCheckboxChange}
            sx={{  height: '30px', mr: 1 }}
          >
            {t('DO_YOU_HAVE_VOUCHER')}
          </CheckboxCustom>
        </Box>
      </div>

      {hasVoucher && (
        <div style={Style.voucherContainer}>
       <TextInput
          id="code"
          name="code"
          onChange={(e) => setClientDebounced(e.target.value)}
          placeholder={t('voucher_placeholder')}
        />
        </div>
      )}

      <Button type="submit" style={Style.loginButton} disabled={loading}>
        {t('EINLOGGEN')}
      </Button>
    </React.Fragment>
  );
}
