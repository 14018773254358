/** @jsxImportSource theme-ui */

import React, { ChangeEvent } from 'react';
import { ThemeUIStyleObject, Label } from 'theme-ui';
import styled from 'styled-components';

interface Props {
  id: string;
  name: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  children: React.ReactNode;
  className?: string;
  sx?: ThemeUIStyleObject;
}

// Create a styled input for the checkbox
const StyledCheckbox = styled.input`
  width: 20px; // Adjust size as needed
  height: 20px; // Adjust size as needed
  margin-right: 6px; // Increased space between checkbox and label
`;

const Checkbox = ({
  id,
  name,
  checked,
  onChange,
  children,
  className,
  sx,
}: Props): React.ReactElement => (
  <Label
    sx={{
      display: 'flex',
      alignItems: 'center',
      marginBottom: 3,
      ...sx,
    }}
    htmlFor={id}
    className={className}
  >
    <StyledCheckbox
      id={id}
      name={name}
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
    <span style={{ flex: 1 }}>{children}</span>
  </Label>
);

export default Checkbox;
